<template>
  <div class="game">
    <header-content></header-content>
    <div id="website_game_wrap">
      <div class="game-wrap">
        <div class="game-top">
          <div class="banner">
            <div class="banner-top" v-if="activeTab === 'game_mir'">
              <div class="game_banner">
                <a href="javascript:void(0);">
                  <span
                    class="banner_img top_banner01"></span>
                </a>
              </div>
              <div class="game-intro">
                <p>
                  《雄霸传奇》由雄霸网络出品，以精致的建模、炫酷的技能以及多处地图细节，为玩家搭建了一个耐玩好看的东方魔幻世界。游戏内装备强力，玩法丰富，人气火爆。玩家可通过练级、转生、强化装备等方式提升战力，还可以组建帮会参加争夺战、跨服争霸等丰富趣味玩法，与兄弟并肩作战，挥洒热血，驰骋沙场，让玩家真正体验到纵横大陆的畅快感。
                </p>
              </div>
            </div>

            <div class="banner-top" v-else-if="activeTab === 'game_woool'">
              <div class="game_banner">
                <a href="javascript:void(0);">
                  <span
                    class="banner_img top_banner02"
                  ></span>
                </a>
              </div>
              <div class="game-intro">
                <p>
                  《雄霸江湖》由雄霸网络出品，舞台设置在百花齐放的武侠世界，以唯美古风背景，交织门派纷争和江湖缠斗，为玩家构建了一个行侠仗义，快意恩仇，刀光剑影的江湖，门派独具特色，技能简单华丽，阵营随心挑选，一切只为给玩家最纯粹的武侠体验。各路势力交锋，抵抗匈奴入侵，切磋论剑擂台决战，金兰情缘仗剑天涯，谁又能百战成名雄霸江湖？
                </p>
              </div>
            </div>

            <div class="banner-top" v-else-if="activeTab === 'game_dn'">
              <div class="game_banner">
                <a href="javascript:void(0);">
                  <span
                    class="banner_img top_banner03"></span>
                </a>
              </div>
              <div class="game-intro">
                <p>
                  《雄霸三国》由雄霸网络出品，以荡气回肠的三国时期为故事背景所制作的大地图即时国战大策略手游，游戏以真实历史地图为蓝本，再现三国鼎立之风云局势，玩家将扮演这乱世中的一名诸侯，在群雄割据的领土争夺中，招募培养名将名士，训练升级特色兵种，谱写属于自己的三国新篇章，业内独创武将羁绊玩法，与心仪的武将结为义兄，组建全明星阵容，共谋天下，三国霸业，指日可待！
                </p>
              </div>
            </div>
            <div class="game-box">
              <ul class="game-menu">
                <li @click="showTab('game_mir')">
                  <a href="javascript:void(0);" :class="tabMir" data-index="0"
                    >传奇系列</a
                  >
                </li>
                <li @click="showTab('game_woool')">
                  <a href="javascript:void(0);" :class="tabWoool" data-index="1"
                    >武侠系列</a
                  >
                </li>
                <li @click="showTab('game_dn')">
                  <a href="javascript:void(0);" :class="tabDn" data-index="2"
                    >其他游戏产品</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="game-main">
          <ul class="game-content game_mir" v-if="activeTab === 'game_mir'">
            <li>
              <div class="game-left">
                <div class="img">
                  <a href="javascript:void(0);"
                    ><img
                      src="../assets/img/game_left01.png"
                      alt=""
                  /></a>
                </div>
                <div class="type">手机游戏</div>
                <div class="act">
                  <div class="act-item home">
                    <a
                       href="javascript:void(0);"
                      alt="官网"
                      title="官网"
                    ></a>
                  </div>
                </div>
              </div>
              <div class="game-info">
                <div class="pic">
                  <img
                    src="../assets/img/game_list01.jpg"
                    alt=""
                  />
                </div>
                <h3>
                  <a
                    href=""
                    alt="进入官网"
                    title="进入官网"
                    class="btn"
                    >进入官网</a
                  >
                  雄霸传奇 <span class="game_type">手机游戏</span>
                </h3>
                <p>
                  《雄霸传奇》是由雄霸网络精心制作的怀旧rpg手游，延续复古元素设定，采用最新技术手法，顶级蓝光级别画质，VIP直送，海量普发福利让游戏无压力更轻松。新世界，新副本、新挑战、多重职业，超强套装、神装靠打、超高爆率、装备变现、自由交易、PK爆物、玩家在这里将不会一个人战斗，百人同屏，千人兄弟、万人攻城，集聚天下英雄好汉，《雄霸传奇》将打造史上最激情的极致热血复古PK手游。
                </p>
              </div>
            </li>
          </ul>

          <ul class="game-content game_woool" v-else-if="activeTab === 'game_woool'">
            <li>
              <div class="game-left">
                <div class="img">
                  <a href="javascript:void(0);" target="_blank"
                    ><img
                      src="../assets/img/game_left02.png"
                      alt=""
                  /></a>
                </div>
                <div class="type">手机游戏</div>
                <div class="act">
                  <div class="act-item home">
                    <a
                       href="javascript:void(0);"
                      alt="官网"
                      title="官网"
                    ></a>
                  </div>
                </div>
              </div>
              <div class="game-info">
                <div class="pic">
                  <img
                    src="../assets/img/game_list02.jpg"
                    alt=""
                  />
                </div>
                <h3>
                  <a
                    href=""
                    alt="进入官网"
                    title="进入官网"
                    class="btn"
                    >进入官网</a
                  >
                  雄霸江湖 <span class="game_type">手机游戏</span>
                </h3>
                <p>
                  《雄霸江湖》雄霸网络旗下工作室国风武侠rpg手游扛鼎之作，高自由度沙盒无缝地图，四海八荒门派任意挑选，武技心法装备自由搭配，华丽时装光彩照人，外观随心更有独一无二，突破传统的帮派经营系统，让每个玩家都有机会建立属于自己的天下第一大帮！盟会逐鹿中原，万人跨服争锋！是与兄弟义结金兰，还是与情缘共觅风月，更多社交玩法等你来体验！在这个会呼吸的武侠世界中，故事由你改写！
                </p>
              </div>
            </li>
          </ul>

          <ul class="game-content game_dn" v-else-if="activeTab === 'game_dn'">
            <li>
              <div class="game-left">
                <div class="img">
                  <a href="javascript:void(0);" target="_blank"
                    ><img
                      src="../assets/img/game_left03.png"
                      alt=""
                  /></a>
                </div>
                <div class="type">手机游戏</div>
                <div class="act">
                  <div class="act-item home">
                    <a
                       href="javascript:void(0);"
                      alt="官网"
                      title="官网"
                    ></a>
                  </div>
                </div>
              </div>
              <div class="game-info">
                <div class="pic">
                  <img
                    src="../assets/img/game_list03.jpg"
                    alt=""
                  />
                </div>
                <h3>
                  <a
                    href=""
                    alt="进入官网"
                    title="进入官网"
                    class="btn"
                    >进入官网</a
                  >
                  雄霸三国 <span class="game_type">手机游戏</span>
                </h3>
                <p>
                  《雄霸三国》雄霸网络三年磨一剑，顶级制作团队打造即时国战大策略手游，不卖资源，不卖土地，一切都要靠自己去打拼，更有海量福利拿到手软，写实人物原画设计，山川湖泊场景搭配，给予玩家最真实的沉浸式体验感，恢弘战场，真实兵种，史诗武将，随心配置，高自由度技能树系统，打造独一无二的最强战力，不但可以同服竞技，还可进行跨服激战，运筹帷幄，决胜千里，攻城略地，一统天下！
                </p>
              </div>
            </li>
          </ul>
        </div>
        <!-- /game-main -->
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>

<script>
// import "../assets/js/jquery-version7";
// import "../assets/js/game.bundle";
import HeaderContent from "@/components/HeaderContent.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "Game",
  components: {
        HeaderContent,
    FooterContent
  },
  data() {
    return {
      activeTab: "game_mir",
      tabMir: "active",
      tabWoool: "",
      tabDn: "",
    };
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      this.$router.replace('/gamem');
    } else {
      // alert("pc端");
      // this.$router.replace('/game');
    }
  },
  methods: {
    showTab(str) {
      this.activeTab = str;
      if (str === "game_mir") {
        this.tabMir = "active";
        this.tabWoool = "";
        this.tabDn = "";
      } else if (str === "game_woool") {
        this.tabMir = "";
        this.tabWoool = "active";
        this.tabDn = "";
      } else if (str === "game_dn") {
        this.tabMir = "";
        this.tabWoool = "";
        this.tabDn = "active";
      }
    },
  },
};
</script>

<style scoped >
@import "../assets/css/index.css";
.game .top_banner01{
background: url('../assets/img/game_banner01.jpg')
                        no-repeat center top;
}
.game .top_banner02{
background: url('../assets/img/game_banner02.jpg')
                        no-repeat center top;
}
.game .top_banner03{
background: url('../assets/img/game_banner03.jpg')
                        no-repeat center top;
}

.game .game-wrap .game-main{
  min-height: 450px;
}
</style>